import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../components/LandingPage/LandingPage.vue'
import ResearchPage from '../components/Research/Research.vue'
import DiscussionsPage from '../components/Discussions/Discussions.vue'
import MinglyApp from '../components/Mingly/MinglyApp.vue'
import TeachingPage from '../components/Teaching/TeachingPage.vue'
import CurriculumVitae from '../components/CV/CurriculumVitae.vue'
import ToolsAndApps from '../components/Tools/ToolsAndApps.vue'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/research',
    name: 'ResearchPage',
    component: ResearchPage
  },
  {
    path: '/discussions',
    name: 'DiscussionsPage',
    component: DiscussionsPage
  },
  {
    path: '/mingly',
    name: 'MinglyApp',
    component: MinglyApp
  },
  {
    path: '/teaching',
    name: 'TeachingPage',
    component: TeachingPage
  },
  {
    path: '/cv',
    name: 'CurriculumVitae',
    component: CurriculumVitae
  },
  {
    path: '/tools',
    name: 'ToolsAndApps',
    component: ToolsAndApps
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
