<template>
  <div class="tools-and-apps">
    <h1>Tools & Apps</h1>
    
    <div class="tools-grid">
      <!-- Option Payoff Tool -->
      <div class="tool-card">
        <div class="tool-content">
          <h2>Option Payoff Diagram</h2>
          <p class="tool-description">
            An interactive web application for visualizing option payoffs at maturity. Create complex option strategies by combining:
          </p>
          <ul class="feature-list">
            <li>Puts and calls with different strike prices</li>
            <li>Underlying stock positions</li>
            <li>Bond positions</li>
            <li>Multiple options for comprehensive strategies</li>
          </ul>
          <div class="tool-links">
            <a href="https://optionpayoff.heyerdahllarsen.com/" target="_blank" class="link-button try-tool">
              <i class="bi bi-graph-up"></i>
              Try Tool
            </a>
          </div>
        </div>
      </div>

      <!-- WKF Scoring App -->
      <div class="tool-card">
        <div class="tool-content">
          <h2>WKF Karate Scoring</h2>
          <p class="tool-description">
            A specialized scoring application for WKF karate kumite matches with features including:
          </p>
          <ul class="feature-list">
            <li>Real-time point display</li>
            <li>Secure judge login system</li>
            <li>50% judge agreement requirement</li>
            <li>Instant score validation</li>
          </ul>
          <div class="tool-links">
            <a href="https://wkf-scoring.web.app/" target="_blank" class="link-button try-tool">
              <i class="bi bi-trophy"></i>
              Launch App
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolsAndApps'
}
</script>

<style scoped>
.tools-and-apps {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin: 40px 0;
}

h2 {
  color: var(--text-color, #ffffff);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.tool-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tool-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.tool-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tool-description {
  color: var(--text-secondary, #cccccc);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
}

.feature-list li {
  color: var(--text-secondary, #cccccc);
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.feature-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--accent-color, #4a90e2);
}

.tool-links {
  margin-top: auto;
  display: flex;
  gap: 1rem;
}

.link-button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
  color: white;
}

.try-tool {
  background-color: var(--accent-color, #4a90e2);
  width: 100%;
  justify-content: center;
}

/* Light mode styles */
:root[data-theme="light"] .tool-card {
  background-color: var(--card-bg, #f5f5f5);
}

:root[data-theme="light"] .tool-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] h1,
:root[data-theme="light"] h2 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .tool-description,
:root[data-theme="light"] .feature-list li {
  color: var(--text-secondary, #666666);
}

@media (max-width: 768px) {
  .tools-grid {
    grid-template-columns: 1fr;
  }
}
</style> 