<template>
  <div class="research">
    <h1>Research Publications</h1>
    <div class="papers-section">
      <div class="paper-card" v-for="(paper, index) in publications" :key="'pub-' + index">
        <div class="paper-content">
          <div class="paper-title">{{ paper.title }}</div>
          <div class="paper-authors">{{ paper.authors }}</div>
          <div class="paper-journal"><i>{{ paper.journal }}</i>, {{ paper.year }}, {{ paper.details }}</div>
          <div class="paper-awards" v-if="paper.awards">
            <div v-for="(award, awardIndex) in paper.awards" :key="'award-' + awardIndex">
              <i>{{ award }}</i>
            </div>
          </div>
        </div>
        <div class="paper-links">
          <a v-if="paper.ssrn" :href="paper.ssrn" target="_blank" class="link-button ssrn">SSRN</a>
          <a v-if="paper.pdf" :href="paper.pdf" target="_blank" class="link-button pdf">PDF</a>
          <a v-if="paper.github" :href="paper.github" target="_blank" class="link-button github">GitHub</a>
          <a v-if="paper.slides" :href="paper.slides" target="_blank" class="link-button slides">Slides</a>
          <a v-if="paper.video" :href="paper.video" target="_blank" class="link-button video">Video</a>
        </div>
      </div>
    </div>

    <h1>Working Papers</h1>
    <div class="papers-section">
      <div class="paper-card" v-for="(paper, index) in workingPapers" :key="'wp-' + index">
        <div class="paper-content">
          <div class="paper-title">{{ paper.title }}</div>
          <div class="paper-authors">{{ paper.authors }}</div>
          <div class="paper-year">{{ paper.year }}</div>
        </div>
        <div class="paper-links">
          <a v-if="paper.ssrn" :href="paper.ssrn" target="_blank" class="link-button ssrn">SSRN</a>
          <a v-if="paper.pdf" :href="paper.pdf" target="_blank" class="link-button pdf">PDF</a>
          <a v-if="paper.github" :href="paper.github" target="_blank" class="link-button github">GitHub</a>
          <a v-if="paper.slides" :href="paper.slides" target="_blank" class="link-button slides">Slides</a>
          <a v-if="paper.video" :href="paper.video" target="_blank" class="link-button video">Video</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResearchPage',
  data() {
    return {
      publications: [
        {
          title: "On Efficiency in Disagreement Economies",
          authors: "C. Heyerdahl-Larsen and J. Walden",
          journal: "Social Choice and Welfare",
          year: "2023",
          details: "61, 763–799",
          pdf: "https://link.springer.com/content/pdf/10.1007/s00355-023-01467-1.pdf"
        },
        {
          title: "Distortions and Efficiency in Production Economies with Heterogeneous Beliefs",
          authors: "C. Heyerdahl-Larsen and J. Walden",
          journal: "Review of Financial Studies",
          year: "2022",
          details: "35 (4), 1775-1812",
          pdf: "https://www.dropbox.com/scl/fi/ypp9ub3kdmfdeawsxud80/welfare2021_wpversion.pdf?rlkey=u1uomt4tdf8ym639nkmvtus6i&dl=0",
        },
        {
          title: "Asset Prices and Portfolio Choice with Learning from Experience",
          authors: "P. Ehling, A. Granniero and C. Heyerdahl-Larsen",
          journal: "Review of Economic Studies",
          year: "2018",
          details: "85 (3), 1752-1780",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2378330",
          pdf: "https://biopen.bi.no/bi-xmlui/bitstream/handle/11250/2558066/Asset%20prices%20and%20portfolio%20choice%202018.pdf?sequence=4&isAllowed=y",
          github: "https://github.com/OLG-Finance/AssetPricingWithLearningFromExperience.git"
        },
        {
          title: "Disagreement about Inflation and the Yield Curve",
          authors: "P. Ehling, M. Gallmeyer, C Heyerdahl-Larsen and P. Illeditsch",
          journal: "Journal of Financial Economics",
          year: "2018",
          details: "127 (3), 459-484",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2028391",
        },
        {
          title: "Risk Premia and Volatilities in a Non-Linear Term Structure Model",
          authors: "P. Feldhütter, C. Heyerdahl-Larsen and P. Illeditsch",
          journal: "Review of Finance",
          year: "2018",
          details: "22 (1), 337-380",
          awards: [
            "Outstanding Paper Award, Wharton's Jacobs Levy Equity Management Center for Quantitative Research, 2014",
            "Best Paper Award, World Finance Conference, 2013"
          ],
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2242280",
        },
        {
          title: "Correlations",
          authors: "P. Ehling and C. Heyerdahl-Larsen",
          journal: "Management Science",
          year: "2017",
          details: "vol. 63. no 6., 1919-1937",
          pdf: "https://biopen.bi.no/bi-xmlui/bitstream/handle/11250/2453779/Correlations%202017.pdf?sequence=1&isAllowed=y"
        },
        {
          title: "Complete and Incomplete Financial Markets in Multi-Good Economies",
          authors: "P. Ehling and C. Heyerdahl-Larsen",
          journal: "Journal of Economic Theory",
          year: "2015",
          details: "160, 438-462",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1102364",
        },
        {
          title: "Asset Pricing and Real Exchange Rates with Deep Habits",
          authors: "C. Heyerdahl-Larsen",
          journal: "Review of Financial Studies",
          year: "2014",
          details: "27 (11), 3280-3317",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1484584",
        },
        {
          title: "Market Selection and Welfare in a Multi-Asset Economy",
          authors: "Y. Fedyk, C. Heyerdahl-Larsen and J. Walden",
          journal: "Review of Finance",
          year: "2013",
          details: "17, 1179-1237",
          pdf: "https://www.dropbox.com/scl/fi/xqnigrmdl0j14qkbc4daj/marketselection.pdf?rlkey=pbr4heonn68msysa3tjbqfn2o&st=k5uc3wyk&dl=0"
        }
      ],
      workingPapers: [
        {
          title: "International Capital Markets and Wealth Transfers",
          authors: "with Magnus Dahlquist, Anna Pavlova and Julien Penasse",
          year: "2024",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4057287",
        },
        {
          title: "The Relative Price Premium",
          authors: "with Yun Joo An, Fotis Grigoris and Preetesh Kantak",
          year: "2025",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4316133",
        },
        {
          title: "Model Selection by Market Selection",
          authors: "with Philipp Illeditsch and Johan Walden",
          year: "2024",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4401170",
        },
        {
          title: "Asset Pricing with the Awareness of New Priced Risks",
          authors: "with Philipp Illeditsch and Petra Sinagl",
          year: "2025",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4281213",
        },
        {
          title: "Belief Driven Entry and Exit",
          authors: "with Paul Ehling and Zeshu Xu",
          year: "2025",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4283101",
        },
        {
          title: "Economic Growth through Diversity in Beliefs",
          authors: "with Philipp Illeditsch and Howard Kung",
          year: "2024",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3490301",
        },
        {
          title: "Demand Disagreement",
          authors: "with Philipp Illeditsch",
          year: "2024",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3092366",
        },
        {
          title: "Factor and stock-specific disagreement and trading flows",
          authors: "with Fotis Grigoris and Preetesh Kantak",
          year: "2022",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3966636",
        },
        {
          title: "The Market View",
          authors: "with Philipp Illeditsch",
          year: "2021",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3762259",
        },
        {
          title: "Asset Prices with Wealth Dispersion",
          authors: "with Paul Ehling and Junjie Guo",
          year: "2021",
          ssrn: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3797399",
        }
      ]
    }
  }
}
</script>

<style scoped>
.research {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin: clamp(1.5rem, 4vw, 2.5rem) 0;
  font-size: clamp(1.5rem, 4vw, 2rem);
}

.papers-section {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 3vw, 1.25rem);
  margin-bottom: clamp(2rem, 5vw, 3rem);
}

.paper-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 8px;
  padding: clamp(1rem, 3vw, 1.25rem);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.paper-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.paper-content {
  color: var(--text-color, #ffffff);
}

.paper-title {
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.paper-authors {
  color: var(--text-secondary, #cccccc);
  margin-bottom: 0.25rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
  line-height: 1.5;
}

.paper-journal, .paper-year {
  color: var(--text-secondary, #b3b3b3);
  margin-bottom: 0.25rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
  line-height: 1.5;
}

.paper-awards {
  margin-top: 0.5rem;
  font-size: clamp(0.85rem, 2vw, 0.95rem);
  line-height: 1.5;
}

.paper-links {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.link-button {
  padding: clamp(0.4rem, 2vw, 0.6rem) clamp(0.8rem, 3vw, 1rem);
  border-radius: 4px;
  text-decoration: none;
  font-size: clamp(0.85rem, 2vw, 0.9rem);
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
}

.ssrn {
  background-color: #4a90e2;
  color: white;
}

.pdf {
  background-color: #e74c3c;
  color: white;
}

.github {
  background-color: #008080;
  color: white;
}

.slides {
  background-color: #2ecc71;
  color: white;
}

.video {
  background-color: #9b59b6;
  color: white;
}

i {
  font-style: italic;
}

/* Light mode styles */
:root[data-theme="light"] .paper-card {
  background-color: var(--card-bg, #f5f5f5);
}

:root[data-theme="light"] .paper-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] h1,
:root[data-theme="light"] .paper-title {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .paper-authors,
:root[data-theme="light"] .paper-journal,
:root[data-theme="light"] .paper-year {
  color: var(--text-secondary, #666666);
}

@media (max-width: 480px) {
  .paper-links {
    justify-content: flex-start;
  }
  
  .link-button {
    flex: 1;
    min-width: auto;
  }
}
</style>
